import React from 'react';
import { MdArrowDownward } from '@react-icons/all-files/md/MdArrowDownward';

export const HeroSection = () => {
    return (
        <div className="relative">
            <div className="container flex h-screen">
                <div className="flex flex-col justify-center">
                    <div className="relative z-10">
                        <h1 className="text-5xl font-semibold mb-6 leading-[1.15em]">
                            Wir arbeiten zusammen, <br />
                            um Kreativität, Technologie <br /> und Strategie zu verbinden.
                        </h1>
                        <p className="text-2xl mb-8">
                            Wir sind eine unabhängige Agentur für Kommunikation, die sich <br />
                            spezialisiert hat, die an die Grenzen digitaler und <br />
                            traditioneller Medien gehen.
                        </p>
                        <a
                            href="/#"
                            className="hero-button block flex items-center w-max pl-6 pr-8 py-4 font-semibold bg-alchem-blue rounded-full gap-2"
                        >
                            <MdArrowDownward size={24} />
                            Lorem Ipsum
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
