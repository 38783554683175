import React, { FC, useRef } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GetHomeLayoutImagesQuery } from '@generated/graphql';
import { DefaultPageProps } from '@layouts/types';
import { BaseFooter } from '@components/base-footer/base-footer';
import { BaseNavigation } from '@components/base-navigation/base-navigation';
import { HTMLHead } from '@components/html-head/html-head';
import classNames from 'classnames';

import { StaticAssetsProvider } from '@providers/static-assets';
import { AbstractCpu } from '@components/webgl-abstract-cpu';
import { HeroSection } from '@components/hero-section/hero-section';
import cssUtils from '../../css/utils';

export const query = graphql`
    query GetHomeLayoutImages($mdxId: String!) {
        mdx(id: { eq: $mdxId }) {
            body
        }
        allFile {
            nodes {
                publicURL
                relativePath
                childImageSharp {
                    id
                    gatsbyImageData(placeholder: BLURRED, quality: 90)
                }
            }
        }
    }
`;

const HomeLayout: FC<DefaultPageProps<GetHomeLayoutImagesQuery>> = (props) => {
    const { pageContext, data, children } = props;

    const contentParentRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <StaticAssetsProvider assets={data.allFile.nodes}>
                <HTMLHead title={pageContext?.frontmatter?.title as string | undefined} />
                <div className={cssUtils.fullScreen.RelativeWrapper}>
                    <div
                        className={classNames(
                            cssUtils.fullScreen.ForegroundWrapper,
                            cssUtils.fullScreen.NonInteractive
                        )}
                    >
                        <AbstractCpu contentParent={contentParentRef} />
                    </div>
                    <header>
                        <BaseNavigation />
                    </header>
                    <section
                        className={classNames(cssUtils.fullScreen.ContentWrapper, cssUtils.fullScreen.OnTop)}
                        ref={contentParentRef}
                    >
                        <HeroSection />
                    </section>
                    <div className="absolute top-0 h-screen bg-white z-0 w-full" />
                    <div className={cssUtils.fullScreen.ContentWrapper}>
                        <main>
                            {data?.mdx && <MDXRenderer>{data.mdx.body}</MDXRenderer>}
                            {children}
                        </main>
                    </div>
                </div>
                <BaseFooter />
            </StaticAssetsProvider>
        </>
    );
};

export default HomeLayout;
