import React, { FC, RefObject, useEffect, useRef } from 'react';
import { windowGlobal } from '../../providers/global-page';

import { AbstractCpuScene } from './abstract-cpu-scene';
import * as styles from './webgl.module.css';

export interface AbstractCpuProps {
    contentParent: RefObject<HTMLDivElement>;
}

export const AbstractCpu: FC<AbstractCpuProps> = ({ contentParent }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let scene: AbstractCpuScene;
        if (contentParent.current && containerRef.current && windowGlobal) {
            scene = new AbstractCpuScene({
                contentParent: contentParent.current,
                container: containerRef.current,
                window: windowGlobal,
            });
        }

        return () => {
            scene?.destroy();
        };
    }, [contentParent, containerRef]);

    return <div ref={containerRef} className={styles.WebGlContainer} />;
};
