import gsap from 'gsap';
import {
    AnimationAction,
    AnimationMixer,
    Clock,
    Color,
    LoopOnce,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    PerspectiveCamera,
    PointLight,
    Scene,
    Vector2,
    WebGLRenderer,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

export interface AbstractCpuSceneProps {
    window: Window;
    container: HTMLDivElement;
    contentParent: HTMLDivElement;
}

export enum ObjectNames {
    PERIPHERY = 'Periphery',
    ATTACHMENT_POINT = 'CPU_6',
}

export enum MaterialNames {
    GLOW = 'GLOW',
}

export enum AnimationClipNames {
    FLOATING = 'Floating',
    ATTACHING = 'Attaching',
}

export class AbstractCpuScene {
    private timeline: gsap.core.Timeline;

    private scrollProgress: number;

    protected scene!: Scene;

    protected camera!: PerspectiveCamera;

    protected composer!: EffectComposer;

    protected renderer!: WebGLRenderer;

    protected mixer!: AnimationMixer;

    protected animations!: Record<AnimationClipNames, AnimationAction>;

    protected materials!: Record<MaterialNames, MeshStandardMaterial>;

    protected objects!: Record<ObjectNames, Object3D>;

    protected clock: Clock;

    protected shouldRender: boolean;

    public constructor(protected props: AbstractCpuSceneProps) {
        const { window, container, contentParent } = props;

        this.renderer = new WebGLRenderer({
            antialias: true,
            alpha: true,
        });

        this.renderer.autoClear = true;
        this.renderer.setClearColor(0xffffff, 1);
        this.renderer.pixelRatio = window.devicePixelRatio;
        container.append(this.renderer.domElement);
        this.composer = new EffectComposer(this.renderer);

        this.clock = new Clock();

        this.timeline = gsap.timeline({
            scrollTrigger: {
                trigger: contentParent,
                start: '100px',
                end: '140%',
                scrub: true,
                markers: false,
                onUpdate: (timeline) => {
                    this.updateScrollPosition(timeline.progress);
                },
            },
        });
        this.scrollProgress = 0;
        this.shouldRender = true;

        this.animate = this.animate.bind(this);
        window.addEventListener('resize', this.resize.bind(this));
        this.loadModel().then(() => {
            // TODO maybe we should find a better place for this logic
            const renderPass = new RenderPass(this.scene, this.camera);
            this.composer.addPass(renderPass);

            const fxaaPass = new ShaderPass(FXAAShader);
            fxaaPass.material.uniforms.resolution.value.x = 1 / (container.offsetWidth * this.renderer.pixelRatio);
            fxaaPass.material.uniforms.resolution.value.y = 1 / (container.offsetHeight * this.renderer.pixelRatio);
            this.composer.addPass(fxaaPass);

            // TODO: Bloom pass does not support alpha transparency yet: https://github.com/mrdoob/three.js/issues/14104#issuecomment-1027546683
            const bloomPass = new UnrealBloomPass(new Vector2(window.innerWidth, window.innerHeight), 0.8, 0.4, 0.85);
            this.composer.addPass(bloomPass);

            // TODO: Bokeh pass does not and will not support alpha transparency: https://github.com/mrdoob/three.js/issues/19223
            const bokehPass = new BokehPass(this.scene, this.camera, {
                focus: 5,
                aperture: 0.0025,
                maxblur: 0.01,
            });
            this.composer.addPass(bokehPass);
        });
    }

    protected resize() {
        const { container } = this.props;
        const { width, height } = container.getBoundingClientRect();
        this.renderer.setSize(width, height);
        this.composer.setSize(width, height);
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
    }

    protected async loadModel(): Promise<void> {
        const loader = new GLTFLoader();

        const gltf = await loader.loadAsync('gltf/abstract-cpu/camera_mockup.glb');

        this.mixer = new AnimationMixer(gltf.scene);

        this.scene = new Scene();
        this.scene.add(gltf.scene);

        const light = new PointLight(new Color(0.8, 0.8, 1));
        light.position.set(-2, 3, -3);
        this.scene.add(light);

        const targetCamera = gltf.cameras.find((camera) => camera.name === 'AnimCam_02_Baked_Orientation');

        if (!targetCamera || !(targetCamera instanceof PerspectiveCamera)) {
            throw new Error(`Camera not found`);
        }

        this.camera = targetCamera;

        this.materials = {} as Record<MaterialNames, MeshStandardMaterial>;
        this.objects = {} as Record<ObjectNames, Object3D>;
        this.scene.traverse((node) => {
            if (node instanceof Mesh) {
                if (node.material instanceof MeshStandardMaterial) {
                    if (node.material.name === MaterialNames.GLOW) {
                        const glowMaterial = node.material;
                        this.materials[MaterialNames.GLOW] = glowMaterial;
                        glowMaterial.transparent = true;
                    }
                }

                if (node.name === ObjectNames.PERIPHERY) {
                    this.objects[ObjectNames.PERIPHERY] = node;
                }

                if (node.name === ObjectNames.ATTACHMENT_POINT) {
                    this.objects[ObjectNames.ATTACHMENT_POINT] = node;
                }
            }
        });

        this.animations = {} as Record<AnimationClipNames, AnimationAction>;
        for (const animation of gltf.animations) {
            switch (animation.name) {
                case AnimationClipNames.FLOATING: {
                    const floatingAnimation = this.mixer.clipAction(animation);
                    this.animations[AnimationClipNames.FLOATING] = floatingAnimation;
                    floatingAnimation.play();
                    break;
                }
                case AnimationClipNames.ATTACHING: {
                    const attachingAnimation = this.mixer.clipAction(animation);
                    this.animations[AnimationClipNames.ATTACHING] = attachingAnimation;
                    attachingAnimation.setLoop(LoopOnce, 1);
                    attachingAnimation.clampWhenFinished = true;
                    attachingAnimation.play();
                    attachingAnimation.paused = true;
                    break;
                }
                default:
                    break;
            }
        }

        this.resize();
        this.updateScrollPosition(this.scrollProgress);
        this.animate();
    }

    protected updateScrollPosition(value: number): void {
        const { container } = this.props;
        this.scrollProgress = value;
        const attachingAnimation = this.animations?.[AnimationClipNames.ATTACHING];
        const floatingAnimation = this.animations?.[AnimationClipNames.FLOATING];
        if (attachingAnimation && floatingAnimation) {
            if (value < 0.1) {
                floatingAnimation.weight = 1 - value * 10;
                attachingAnimation.weight = value * 10;
            } else {
                floatingAnimation.weight = 0;
                attachingAnimation.weight = 1;
                attachingAnimation.time = value * 10;
            }
        }
        this.shouldRender = value >= 0 && value < 1;
        // TODO: Remove opacity hack when composer transparency is resolved
        if (value > 0.55) {
            container.style.opacity = `${1 - (value - 0.55) * 5}`;
        } else {
            container.style.opacity = '1';
        }
    }

    protected animate() {
        requestAnimationFrame(this.animate);
        this.mixer?.update(this.clock.getDelta());

        if (this.shouldRender) {
            this.render();
        }
    }

    protected render() {
        if (this.composer && this.scene && this.camera) {
            this.composer.render();
        }
    }

    public destroy() {
        this.renderer.domElement.remove();
        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.timeline.kill();
    }
}
